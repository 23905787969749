import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import ArrowUpwardTwoToneIcon from '@material-ui/icons/ArrowUpwardTwoTone';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/styles';

import agreementTxt from './privacyAgData.json';
const styles = (): StyleRules =>
  createStyles({
    textContent: {
      textAlign: 'center',

      color: '#333',
      letterSpacing: '0.05em',
      scrollbarWidth: 'none', // "auto" or "none",
      msOverflowStyle: 'none', // "auto" or "none",
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    textarea: {
      padding: '2rem',
      width: '100%',
      height: '99vh',
      border: 'none',
      backgroundColor: 'transparent',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      resize: 'none',
      outline: 'none',
    },
    icon: {
      position: 'fixed',
      bottom: '3rem',
      right: '1.5rem',
      cursor: 'pointer',
    },
    h1: {
      marginTop: '2rem',
    },
  });
interface ExtendedLocation extends Location {
  state?: { examine: boolean; bidRequest: boolean; closed: boolean };
}

const Agreement = ({ classes }: Props): React.ReactElement => {
  const containerRef = useRef(null);

  const scrollToTop = () => {
    const box = (containerRef.current as unknown) as HTMLElement;
    if (box) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      box.scrollTop = 0;
    }
  };

  return (
    <>
      <Helmet title={agreementTxt.title}>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <div className={classes.textContent}>
        <h1 className={classes.h1}>{agreementTxt.title}</h1>

        <textarea
          className={classes.textarea}
          value={agreementTxt.content}
          disabled={true}
          ref={containerRef}
        ></textarea>
        <ArrowUpwardTwoToneIcon
          className={classes.icon}
          fontSize="large"
          color="primary"
          onClick={scrollToTop}
        />
      </div>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  location: ExtendedLocation;
}
export default withStyles(styles)(Agreement);
